<template>
  <div class="bodycont">

    <comHeader />
    <div class="main">
     <!-- <div class="rightcont">
        <div  class="news_list"><div class="top_title"><h4 >需求库</h4></div>
          </div>
      </div> -->
      <div class="infodiv">
        <div class="msgdiv">
          <ul class="list_txt">
            <li v-for="item in dataList" :key='item.newId' @click="tourl(item)">
              <div class="title">
                <span>{{item.ureqName}}</span>

              </div>
              <div class="timediv">
                    <span class="time">联系人:{{item.ureqPerson}}</span>
                  <span class="time">发布时间:{{item.createtime}}</span>
                </div>
            </li>
          </ul>
          <div class="pages" v-if="dataList.length>0">
            <el-pagination v-if="totalSize>10" background layout="prev, pager, next" :current-page="page.page" :page-size="page.limit"
            	 :total="totalSize" @current-change="handleCurrentChange"></el-pagination>

          </div>
        </div>
      </div>
    </div>

    <bNav />

  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      navTitle,
    },
   data() {
     return {
       cindex:1,
       dataList:[],
       page:{
         page:1,
         limit:20,
       },
       totalSize:0,
     }
   },
   created() {

   },
   mounted() {
       this.getList()
   },
   methods: {
     //获取列表
     getList() {
       let data = {
         page:this.page.page,
         limit:this.page.limit,
       }
       postRequest(api.selectAllPage, data).then(res => {
          this.dataList = res.page.list
          this.totalSize = Number(res.page.totalPage)
       })

     },
     handleCurrentChange(val) {
       this.page.page = val;
     },
      //详情页面
     tourl(item){
       this.$router.push({
         path:'/pdetails',
         query:{
           id:item.ureqId,
         }
       })
     }
    }
  }
</script>

<style lang="less" scoped>
  .bodycont {
    margin: 0px;
    background-size: 100% 100%;
    background-attachment: fixed;
  }

  .main {
    width:1190px;
    margin: 20px auto 0;
    display: flex;
    // justify-content: space-between;
  }
  .infodiv{
    width: 1190px;
    margin: 0 auto 0px;
    min-height:75vh;

  }
  .msgdiv{

  }
  .list_txt {
    list-style: none;

    li {
      height: 50px;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .title {
        display: flex;
         justify-content: flex-start; ;
        align-items: center;
       width: 50%;
       text-overflow: ellipsis;
       white-space: nowrap;

       overflow: hidden;
        img {
          width: 30px;
          height: 30px;
        }

        span {
          display: inline-block;
          font-size: 16px;
          color: #333333;
          line-height: 50px;
          padding-left: 6px;

        }
      }
     .timediv{    width: 50%;
          display: flex;
          align-items: center;
              justify-content:flex-end;
      }
      .time {
        display: block;
        line-height: 50px;
        font-size: 16px;
        color: #333333;
        padding-left: 10px;
      }
    }
  }
  .msgdiv{
    position: relative;

    .pages {
      width: 80%;
      margin: 20px auto;
      text-align: center;
    }
  }

  .news_list {
    width: 200px;
    overflow: hidden;
    float: left;

    .top_title {
      width: 100%;
      height: 54px;
      background: url(../../assets/images/title.png) no-repeat;
      text-align: center;

      h4 {
        color: #fff;
        font-size: 18px;
        line-height: 54px;
      }
    }

    .zczx {
      padding: 0px 0px;
      background: #fff;

      .cur{
        color: #00A0E9;
      }

      li {
          font-size: 16px;
          color: #333;
          background: #eee;
          display: block;
          width: 100%;
          height: 40px;
          line-height: 40px;
          margin-bottom: 2px;
          text-align: center;
          padding-left: 0;
          border: none;
          position: relative;
          cursor: pointer;
      }
    }
  }
</style>
